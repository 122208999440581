export const canHaveImpact = (nodeType) => {
    return nodeType === "ASSET" || nodeType === "MEDIATOR";
};
const nodeTypesThatCanHaveImpactChildren = new Set([
    "ASSET",
    "MEDIATOR",
]);
export const doesNodeTypeImpactChildren = (nodeType) => {
    return nodeTypesThatCanHaveImpactChildren.has(nodeType);
};
const nodeTypesThatCanHaveParent = new Set([
    "MEDIATOR",
    "INSTANCE",
]);
export const doesNodeTypeHaveParent = (nodeType) => {
    return nodeTypesThatCanHaveParent.has(nodeType);
};
