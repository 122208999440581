import { computed, toValue } from "vue";
import { useComponent } from "./useComponent";
export const useComponentVariable = (componentId, variableName) => {
    const { component } = useComponent(componentId);
    const componentVariable = computed(() => {
        var _a;
        return (_a = component.value) === null || _a === void 0 ? void 0 : _a.variables.find((variable) => variable.name === toValue(variableName));
    });
    return { componentVariable };
};
