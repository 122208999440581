import { defineStore } from "pinia";
import { reactive } from "vue";
export const isVariablesBuildFailedError = (error) => {
    return (error === null || error === void 0 ? void 0 : error.code) === "settings_build_failed";
};
export const useComponentVariableStateStore = defineStore("useComponentVariableStateStore", () => {
    const state = reactive({
        componentVariableState: {},
    });
    const getOrCreateComponentVariableState = (componentMutableKey, variableName) => {
        if (!state[componentMutableKey]) {
            state[componentMutableKey] = {};
        }
        if (!state[componentMutableKey][variableName]) {
            state[componentMutableKey][variableName] = {
                isLoading: false,
                error: null,
            };
        }
        return state[componentMutableKey][variableName];
    };
    const isLoading = (componentMutableKey, variableName) => {
        return getOrCreateComponentVariableState(componentMutableKey, variableName).isLoading;
    };
    const startLoading = (componentMutableKey, variableName) => {
        getOrCreateComponentVariableState(componentMutableKey, variableName).isLoading = true;
    };
    const stopLoading = (componentMutableKey, variableName) => {
        getOrCreateComponentVariableState(componentMutableKey, variableName).isLoading = false;
    };
    const registerVariablesBuildFailedError = (componentMutableKey, error) => {
        for (const [variableName, variableError] of Object.entries(error.parameters.errors)) {
            state[componentMutableKey][variableName].error = variableError;
        }
    };
    const clearError = (componentMutableKey, variableName) => {
        state[componentMutableKey][variableName].error = null;
    };
    const getError = (componentMutableKey, variableName) => {
        var _a, _b, _c;
        // return state[componentMutableKey][variableName].error;
        // Handle the case where the componentMutableKey or variableName is not found
        return (_c = (_b = (_a = state[componentMutableKey]) === null || _a === void 0 ? void 0 : _a[variableName]) === null || _b === void 0 ? void 0 : _b.error) !== null && _c !== void 0 ? _c : null;
    };
    return {
        state,
        isLoading,
        startLoading,
        stopLoading,
        registerVariablesBuildFailedError,
        clearError,
        getError,
    };
});
export const useComponentMutableStateStore = defineStore("useComponentMutableStateStore", () => {
    const state = reactive({});
    const getOrCreateComponentMutableState = (componentMutableKey) => {
        if (!state[componentMutableKey]) {
            state[componentMutableKey] = {
                isLoading: false,
            };
        }
        return state[componentMutableKey];
    };
    const isLoading = (componentMutableKey) => {
        return getOrCreateComponentMutableState(componentMutableKey)
            .isLoading;
    };
    const startLoading = (componentMutableKey) => {
        getOrCreateComponentMutableState(componentMutableKey).isLoading =
            true;
    };
    const stopLoading = (componentMutableKey) => {
        getOrCreateComponentMutableState(componentMutableKey).isLoading =
            false;
    };
    return {
        state,
        isLoading,
        startLoading,
        stopLoading,
    };
});
