import { isComponentVariableTranslatable, toggleComponentVariableTranslatableTag, } from "src/shared/tags/component-variables";
import { computed, toValue } from "vue";
export const useComponentVariableTranslatableMutable = (componentVariableMutable) => {
    const translatable = computed({
        get() {
            const componentVariableMutableValue = toValue(componentVariableMutable);
            if (!componentVariableMutableValue) {
                return false;
            }
            return isComponentVariableTranslatable(componentVariableMutableValue.getTags());
        },
        set() {
            const componentVariableMutableValue = toValue(componentVariableMutable);
            if (!componentVariableMutableValue) {
                return;
            }
            const tags = [...componentVariableMutableValue.getTags()];
            toggleComponentVariableTranslatableTag(tags);
            componentVariableMutableValue.setTags(tags);
        },
    });
    return {
        translatable,
    };
};
