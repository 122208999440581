import $ from "jquery";
const PATH = "api:///scenario";
const getConversationMessages = (handleId) => $.ajax({
    method: "GET",
    url: `${PATH}/conversations/${handleId}`,
});
const addConversationMessage = (handleId, request) => $.ajax({
    method: "POST",
    url: `${PATH}/conversations/${handleId}/messages`,
    contentType: "application/json",
    data: JSON.stringify(request),
});
const getScenarios = () => $.ajax({
    method: "GET",
    url: `${PATH}/scenarios`,
});
const getScenario = (scenario) => $.ajax({
    method: "GET",
    url: `${PATH}/scenarios/${scenario}`,
});
const startConversation = (payload) => $.ajax({
    method: "POST",
    url: `${PATH}/conversations`,
    contentType: "application/json",
    data: JSON.stringify(payload),
});
const startConversationWithMessage = (payload) => $.ajax({
    method: "POST",
    url: `${PATH}/conversations/messages`,
    contentType: "application/json",
    data: JSON.stringify(payload),
    xhrFields: {
        withCredentials: false,
    },
    beforeSend: function () {
        document.cookie =
            "extole_access_token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
    },
});
const getConversation = (id) => $.ajax({
    method: "GET",
    url: `${PATH}/conversations/${id}`,
});
export default {
    getConversationMessages,
    addConversationMessage,
    getScenarios,
    getScenario,
    startConversation,
    startConversationWithMessage,
    getConversation,
};
