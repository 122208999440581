var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ref } from "vue";
import { isCampaignComponentEnumVariable, } from "../../campaign-component/variable/model/CampaignComponentEnumVariable";
import { ComponentVariableMutable } from "./ComponentVariableMutable";
import { useComponentVariable } from "../../component/composables/useComponentVariable";
import { until } from "@vueuse/core";
export const isEnumComponentVariableMutable = (variable) => {
    return variable.getType() === "ENUM";
};
export class EnumComponentVariableMutable extends ComponentVariableMutable {
    constructor() {
        super(...arguments);
        this.lastPulledAllowedValues = ref([]);
        this.allowedValues = ref([]);
        this.getAllowedValues = () => {
            return this.allowedValues.value;
        };
        this.setAllowedValues = (allowedValues) => {
            this.allowedValues.value = allowedValues;
        };
        this.isDirty = () => {
            return (JSON.stringify(this.allowedValues.value) !==
                JSON.stringify(this.lastPulledAllowedValues.value) ||
                super.isDirty());
        };
    }
    pull() {
        const _super = Object.create(null, {
            pull: { get: () => super.pull }
        });
        return __awaiter(this, void 0, void 0, function* () {
            const { componentVariable } = useComponentVariable(this.context.componentId, this.name);
            yield until(componentVariable).not.toBeUndefined();
            if (!componentVariable.value) {
                return;
            }
            if (!isCampaignComponentEnumVariable(componentVariable.value)) {
                return;
            }
            this.lastPulledAllowedValues.value =
                componentVariable.value.allowed_values;
            this.allowedValues.value = this.lastPulledAllowedValues.value;
            _super.pull.call(this);
        });
    }
    serializeToUpdateRequest() {
        return Object.assign(Object.assign({}, super.serializeToUpdateRequest()), { allowed_values: this.allowedValues.value });
    }
    serializeToCreateRequest() {
        return Object.assign(Object.assign({}, super.serializeToCreateRequest()), { allowed_values: this.allowedValues.value });
    }
}
