import CampaignComponentVariable from "./CampaignComponentVariable";
export const isCampaignComponentEnumVariable = (variable) => {
    return variable.type === "ENUM";
};
export class CampaignComponentEnumVariable extends CampaignComponentVariable {
    constructor(response, context) {
        super(response, context);
        this.allowed_values = response.allowed_values;
    }
}
