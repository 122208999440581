var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { defineStore } from "pinia";
import { reactive } from "vue";
import AiConversationEndpoints from "src/shared/api/ai-conversation/AiConversationEndpoints";
import { toPromise } from "src/shared/api/promise";
import { chain } from "lodash";
import Scenario from "./model/Scenario";
import { ConversationMessage } from "./model/ConversationMessage";
function parseConversationResponse(response) {
    const messages = response.map((message) => {
        return new ConversationMessage(message);
    });
    return messages;
}
export const useAiConversationStore = defineStore("aiConversationStore", () => {
    const state = reactive({
        scenarios: [],
        conversationMessages: {},
    });
    const fetchConversationMessages = (conversationId) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const response = yield toPromise(AiConversationEndpoints.getConversationMessages(conversationId));
            const messages = parseConversationResponse(response.messages);
            state.conversationMessages[conversationId] = messages;
        }
        catch (error) {
            return error;
        }
    });
    const getScenarios = () => {
        return state.scenarios;
    };
    const fetchScenarios = () => __awaiter(void 0, void 0, void 0, function* () {
        const scenarioResponses = yield toPromise(AiConversationEndpoints.getScenarios());
        const scenarios = chain(scenarioResponses)
            .map((response) => {
            return new Scenario(response);
        })
            .value();
        state.scenarios = scenarios;
    });
    const startConversation = (scenarioName, context) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield toPromise(AiConversationEndpoints.startConversation({
            scenario: scenarioName,
            context,
        }));
        return response.id;
    });
    const startConversationWithMessage = (scenarioName, prompt, context) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield toPromise(AiConversationEndpoints.startConversationWithMessage({
            scenario: scenarioName,
            prompt,
            context: context,
        }));
        yield fetchConversationMessages(response.id);
        return response.id;
    });
    const fetchConversation = (id) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield toPromise(AiConversationEndpoints.getConversation(id));
    });
    const optimisticallyAddUserMessage = (conversationId, message) => {
        state.conversationMessages[conversationId] =
            state.conversationMessages[conversationId] || [];
        const conversationMessages = state.conversationMessages[conversationId];
        const newMessage = {
            type: "USER",
            content: message,
        };
        conversationMessages.push(newMessage);
    };
    const addConversationMessage = (conversationId, payload) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            optimisticallyAddUserMessage(conversationId, payload.prompt);
            yield toPromise(AiConversationEndpoints.addConversationMessage(conversationId, payload));
        }
        catch (error) {
            return error;
        }
        finally {
            yield fetchConversationMessages(conversationId);
        }
    });
    const getConversationMessages = (conversationId) => {
        const messages = state.conversationMessages[conversationId] || {};
        return Object.values(messages);
    };
    return {
        addConversationMessage,
        getScenarios,
        fetchScenarios,
        startConversation,
        startConversationWithMessage,
        fetchConversation,
        fetchConversationMessages,
        getConversationMessages,
    };
});
