import { EVALUATABLE_PREFIX_PATTERN } from "src/shared/evaluatable";
const variableRegex = /(\{\{[^}]+\}\}|\{\[[^\]]+\]\})/g;
const cleanEvaluatablePrefix = (value) => {
    return value.replace(EVALUATABLE_PREFIX_PATTERN, "");
};
const removeComponentVariableHandlebarsSyntax = (value) => {
    return value.replace(/\{\{|\}\}/g, "").trim();
};
const removeRendertimeVariableHandlebarsSyntax = (value) => {
    return value.replace(/\{\[|\]\}/g, "").trim();
};
export const calculateStringHandlebarsPreviewSegments = (template) => {
    if (typeof template !== "string") {
        return [];
    }
    const segments = template.split(variableRegex);
    return segments.map((value) => {
        if (value.match(/\{\{[^}]+\}\}/)) {
            return {
                type: "component-variable",
                value: removeComponentVariableHandlebarsSyntax(value),
            };
        }
        else if (value.match(/\{\[[^\]]+\]\}/)) {
            return {
                type: "rendertime-variable",
                value: removeRendertimeVariableHandlebarsSyntax(value),
            };
        }
        else {
            return {
                type: "plaintext",
                value: cleanEvaluatablePrefix(value),
            };
        }
    });
};
export const isExpression = (value) => {
    return (value.match(variableRegex) !== null ||
        value.match(EVALUATABLE_PREFIX_PATTERN) !== null);
};
