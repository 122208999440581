import { computed, toValue } from "vue";
export const useResolvedWorkingVariant = (targetVariant, defaultVariant, componentVariableMutable) => {
    const resolvedWorkingVariant = computed(() => {
        const targetVariantValue = toValue(targetVariant);
        const defaultVariantValue = toValue(defaultVariant);
        const componentVariableMutableValue = toValue(componentVariableMutable);
        if (!componentVariableMutableValue || !defaultVariantValue) {
            return undefined;
        }
        if (componentVariableMutableValue === null || componentVariableMutableValue === void 0 ? void 0 : componentVariableMutableValue.hasVariant(targetVariantValue)) {
            return targetVariantValue;
        }
        if (componentVariableMutableValue === null || componentVariableMutableValue === void 0 ? void 0 : componentVariableMutableValue.hasVariant(defaultVariantValue)) {
            return defaultVariantValue;
        }
        return "default";
    });
    return { resolvedWorkingVariant };
};
