var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import CampaignComponentVariable from "../../campaign-component/variable/model/CampaignComponentVariable";
import { useComponent } from "../../component/composables";
import { until } from "@vueuse/core";
import { ref, shallowReactive } from "vue";
import { campaignComponentUpdatedEvent } from "../../event-bus/campaign-component";
import { createComponentVariableMutable } from "./factory/componentVariableMutableFactory";
import { CampaignComponentEnumVariable } from "../../campaign-component/variable/model/CampaignComponentEnumVariable";
import { EnumComponentVariableMutable } from "./EnumComponentVariableMutable";
const createNewComponentVariableMutable = (parameters) => {
    const uniqueNewVariableName = `new_variable_${Date.now()}`;
    if (parameters.variableType === "ENUM") {
        const enumVariable = new CampaignComponentEnumVariable({
            name: uniqueNewVariableName,
            priority: "0",
            display_name: null,
            description: null,
            tags: [],
            source: "LOCAL",
            type: "ENUM",
            values: {},
            allowed_values: [],
        }, {
            campaignId: parameters.campaignId,
            componentId: parameters.componentId,
        });
        const enumVariableMutable = new EnumComponentVariableMutable(parameters.componentMutableKey, "NEW", enumVariable);
        enumVariableMutable.addVariant("default");
        return enumVariableMutable;
    }
    const variable = new CampaignComponentVariable({
        name: uniqueNewVariableName,
        priority: "0",
        display_name: null,
        description: null,
        tags: [],
        source: "LOCAL",
        type: "STRING",
        values: {},
    }, {
        campaignId: parameters.campaignId,
        componentId: parameters.componentId,
    });
    const variableMutable = createComponentVariableMutable(parameters.componentMutableKey, "NEW", variable);
    variableMutable.addVariant("default");
    return variableMutable;
};
export class ComponentMutable {
    constructor(type, componentMutableKey, component) {
        this.variables = shallowReactive({});
        this.getName = () => {
            return this.name.value;
        };
        this.setName = (name) => {
            this.name.value = name;
        };
        this.pull = () => __awaiter(this, void 0, void 0, function* () {
            const { component } = useComponent(this.component_id);
            yield until(component).not.toBeUndefined();
            if (!component.value) {
                return;
            }
            this.lastPulledName.value = component.value.name;
            this.name.value = this.lastPulledName.value;
            const variableNames = component.value.variables.map((variable) => variable.name);
            const currentVariableNames = Object.keys(this.variables);
            // REMOVE VARIABLES THAT NO LONGER EXIST
            const variablesToRemove = currentVariableNames.filter((variableName) => !variableNames.includes(variableName));
            variablesToRemove.forEach((variableName) => {
                delete this.variables[variableName];
            });
            // ADD NEW VARIABLES
            const variablesToAdd = variableNames.filter((variableName) => !currentVariableNames.includes(variableName));
            variablesToAdd.forEach((variableName) => {
                var _a;
                const variable = (_a = component.value) === null || _a === void 0 ? void 0 : _a.findVariable(variableName);
                if (!variable) {
                    return;
                }
                this.variables[variableName] = createComponentVariableMutable(this.componentMutableKey, this.type, variable);
            });
            // PULL VARIABLES
            const promises = Object.values(this.variables).map((variable) => variable.pull());
            yield Promise.all(promises);
        });
        this.getVariable = (variableName) => {
            return this.variables[variableName];
        };
        this.addVariable = (variableType) => {
            const variableMutable = createNewComponentVariableMutable({
                variableType,
                componentMutableKey: this.componentMutableKey,
                componentId: this.component_id,
                campaignId: this.campaign_id,
            });
            this.variables[variableMutable.name] = variableMutable;
            return {
                uniqueNewVariableName: variableMutable.name,
            };
        };
        this.getDirtyVariables = () => {
            return Object.values(this.variables).filter((variable) => variable.isDirty());
        };
        this.getVariables = () => {
            return Object.values(this.variables);
        };
        this.areAttributesDirty = () => {
            return this.name.value !== this.lastPulledName.value;
        };
        this.areVariablesDirty = () => {
            return Object.values(this.variables).some((variable) => variable.isDirty());
        };
        this.isDirty = () => {
            return this.areAttributesDirty() || this.areVariablesDirty();
        };
        this.componentMutableKey = componentMutableKey;
        this.component_id = component.id;
        this.campaign_id = component.campaign_id;
        this.type = type;
        this.tags = [...component.tags];
        this.name = ref(component.name);
        this.lastPulledName = ref(component.name);
        campaignComponentUpdatedEvent.on((event) => {
            if (event.componentId === this.component_id) {
                this.pull();
            }
        });
    }
}
