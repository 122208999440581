import { computed, toValue } from "vue";
export const useComponentVariableDisplayNameMutable = (componentVariableMutable) => {
    const displayName = computed({
        get() {
            const componentVariableMutableValue = toValue(componentVariableMutable);
            if (!componentVariableMutableValue) {
                return "";
            }
            return componentVariableMutableValue.getDisplayName();
        },
        set(value) {
            const componentVariableMutableValue = toValue(componentVariableMutable);
            if (!componentVariableMutableValue) {
                return;
            }
            if (value === "") {
                componentVariableMutableValue.setDisplayName(null);
            }
            else {
                componentVariableMutableValue.setDisplayName(value);
            }
        },
    });
    return {
        displayName,
    };
};
