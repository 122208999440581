var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { defineStore } from "pinia";
import { until } from "@vueuse/core";
import { useComponent } from "../component/composables";
import { ComponentMutable } from "./model/ComponentMutable";
import { uniqueId } from "lodash";
import { reactive, shallowReactive } from "vue";
export const useComponentMutableStore = defineStore("useComponentMutableStore", () => {
    const state = shallowReactive({});
    const componentMutableKeysByExternalHandle = reactive({});
    const createComponentMutable = (parameters) => __awaiter(void 0, void 0, void 0, function* () {
        const { component } = useComponent(parameters.componentId);
        yield until(component).not.toBeUndefined();
        if (!component.value) {
            throw new Error("Component not found");
        }
        const componentMutableKey = uniqueId("componentMutable");
        const componentMutable = new ComponentMutable(parameters.type, componentMutableKey, component.value);
        yield componentMutable.pull();
        state[componentMutableKey] = componentMutable;
        if (parameters.externalHandle) {
            const componentMutableKeys = getOrCreateHandle(parameters.externalHandle);
            componentMutableKeys.add(componentMutableKey);
        }
        return { componentMutableKey };
    });
    const getComponentMutable = (componentMutableKey) => {
        if (!componentMutableKey) {
            return;
        }
        return state[componentMutableKey];
    };
    const getOrCreateHandle = (externalHandle) => {
        if (!componentMutableKeysByExternalHandle[externalHandle]) {
            componentMutableKeysByExternalHandle[externalHandle] =
                new Set();
        }
        return componentMutableKeysByExternalHandle[externalHandle];
    };
    const getComponentMutablesByExternalHandle = (externalHandle) => {
        const componentMutableKeys = getOrCreateHandle(externalHandle);
        const output = [];
        componentMutableKeys.forEach((componentMutableKey) => {
            const componentMutable = getComponentMutable(componentMutableKey);
            if (componentMutable) {
                output.push(componentMutable);
            }
        });
        return output;
    };
    return {
        state,
        createComponentMutable,
        getComponentMutable,
        getComponentMutablesByExternalHandle,
    };
});
