import { provideFilteredComponentVariableNames } from "./useFilteredComponentVariables";
export const isComponentVariableShown = (variableName, componentMutableKey) => {
    const { filteredVariableNames } = provideFilteredComponentVariableNames(componentMutableKey);
    return filteredVariableNames.value.variableNames.has(variableName);
};
export const isComponentVariablePrimaryCategoryShown = (primaryCategory, componentMutableKey) => {
    const { filteredVariableNames } = provideFilteredComponentVariableNames(componentMutableKey);
    return filteredVariableNames.value.primaryCategories.has(primaryCategory);
};
export const isComponentVariableSecondaryCategoryShown = (secondaryCategory, componentMutableKey) => {
    const { filteredVariableNames } = provideFilteredComponentVariableNames(componentMutableKey);
    return filteredVariableNames.value.secondaryCategories.has(secondaryCategory);
};
export const hasNoResults = (componentMutableKey) => {
    const { filteredVariableNames } = provideFilteredComponentVariableNames(componentMutableKey);
    return filteredVariableNames.value.variableNames.size === 0;
};
