import { memoize } from "lodash";
import { useFuzzySearch } from "src/shared/store/common/fuzzy-search";
import { useBuiltComponentVariables } from "src/shared/store/component-built/composables";
import { useParentBuiltComponents, } from "src/shared/store/component-graph/composables";
import { useComponentMutable } from "src/shared/store/component-mutable/composables/useComponentMutable";
import { useComponentVariableMutableFilter } from "src/shared/store/component-variable-mutable/composables/useComponentVariableMutableSetFilter";
import { computed, reactive } from "vue";
import { useComponentVariableAdvancedMasterSwitch } from "../../composables/useComponentVaraiableAdvancedMasterSwitch";
const hasParentComponentVariable = (variableName, parentComponents) => {
    for (const parentComponent of parentComponents) {
        const variable = parentComponent.findVariable(variableName);
        if (variable) {
            return true;
        }
    }
    return false;
};
export const provideFilteredComponentVariableNames = memoize((componentMutableKey) => {
    const { showAdvanced } = useComponentVariableAdvancedMasterSwitch();
    const { componentMutable } = useComponentMutable(componentMutableKey);
    const componentId = computed(() => { var _a; return (_a = componentMutable.value) === null || _a === void 0 ? void 0 : _a.component_id; });
    const { componentVariableMutableFilter } = useComponentVariableMutableFilter(componentMutableKey);
    const { builtComponentVariables } = useBuiltComponentVariables(componentId);
    const search = computed(() => componentVariableMutableFilter.value.search);
    const parentBuiltComponents = useParentBuiltComponents(componentId);
    const fuzzySearched = useFuzzySearch(search, builtComponentVariables, [
        "name",
    ]);
    const filteredByOtherParameters = computed(() => {
        return fuzzySearched.value.filter((variable) => {
            const everyConditionMustBeTruthy = [];
            if (componentVariableMutableFilter.value.showOnlyTranslatable) {
                everyConditionMustBeTruthy.push(variable.isTranslatable());
            }
            if (componentVariableMutableFilter.value.showOnlyEssential) {
                everyConditionMustBeTruthy.push(variable.getImportance() === "ESSENTIAL");
            }
            if (componentVariableMutableFilter.value.primaryCategory) {
                everyConditionMustBeTruthy.push(variable.getPrimaryCategory() ===
                    componentVariableMutableFilter.value.primaryCategory);
            }
            if (componentVariableMutableFilter.value
                .showOnlyNotUsingDefaultValue) {
                everyConditionMustBeTruthy.push(!variable.isInheriting() &&
                    hasParentComponentVariable(variable.name, parentBuiltComponents.value));
            }
            if (componentVariableMutableFilter.value
                .showOnlyUsingDefaultValue) {
                everyConditionMustBeTruthy.push(variable.isInheriting());
            }
            if (!showAdvanced()) {
                const isAdvanced = variable.isAdvanced();
                everyConditionMustBeTruthy.push(!isAdvanced);
            }
            if (everyConditionMustBeTruthy.length === 0) {
                return true;
            }
            return everyConditionMustBeTruthy.every((condition) => condition);
        });
    });
    // return an es6 Set
    const filteredVariableNames = computed(() => {
        const filteredThings = reactive({
            primaryCategories: new Set(),
            secondaryCategories: new Set(),
            variableNames: new Set(),
        });
        filteredByOtherParameters.value.forEach((variable) => {
            filteredThings.primaryCategories.add(variable.getPrimaryCategory());
            filteredThings.secondaryCategories.add(variable.getSecondaryCategory());
            filteredThings.variableNames.add(variable.name);
        });
        return filteredThings;
    }
    // new Set(
    //     filteredByOtherParameters.value.map(
    //         (variable) => variable.name
    //     )
    // )
    );
    return {
        filteredVariableNames,
    };
});
