import { computed, toValue } from "vue";
import { useComponentMutableStore } from "..";
export const useComponentMutable = (componentMutableKey) => {
    const componentMutableStore = useComponentMutableStore();
    const componentMutable = computed(() => {
        return componentMutableStore.getComponentMutable(toValue(componentMutableKey));
    });
    return {
        componentMutable,
    };
};
