import { useElementHover } from "@vueuse/core";
import { computed, ref, watch } from "vue";
export const useElementHoverDelay = (element, delay) => {
    const timeout = ref(null);
    const isHovering = useElementHover(element);
    const hasBeenHoveredForLongEnough = ref(false);
    const output = computed(() => {
        return isHovering.value && hasBeenHoveredForLongEnough.value;
    });
    watch(isHovering, (value) => {
        if (value) {
            if (timeout.value !== null) {
                clearTimeout(timeout.value);
            }
            timeout.value = setTimeout(() => {
                hasBeenHoveredForLongEnough.value = true;
            }, delay);
        }
        else {
            hasBeenHoveredForLongEnough.value = false;
        }
    });
    return output;
};
