import { computed, toValue } from "vue";
import { useComponentVariableStateStore } from "../component-variable-state";
export const useRawVariantValue = (targetVariant, resolvedVariant, componentVariableMutable, mode) => {
    const componentVariableStateStore = useComponentVariableStateStore();
    const rawVariantValue = computed({
        get() {
            const resolvedVariantValue = toValue(resolvedVariant);
            const componentVariableMutableValue = toValue(componentVariableMutable);
            if (!resolvedVariantValue || !componentVariableMutableValue) {
                return undefined;
            }
            return componentVariableMutableValue.getValue(resolvedVariantValue);
        },
        set(value) {
            const targetVariantValue = toValue(targetVariant);
            const resolvedVariantValue = toValue(resolvedVariant);
            const modeValue = toValue(mode);
            const componentVariableMutableValue = toValue(componentVariableMutable);
            if (!resolvedVariantValue || !componentVariableMutableValue) {
                return;
            }
            if (modeValue === "SINGLE_VARIANT") {
                componentVariableMutableValue.setValue(resolvedVariantValue, value);
            }
            if (modeValue === "MULTI_VARIANT") {
                componentVariableMutableValue.setValue(targetVariantValue, value);
            }
            componentVariableStateStore.clearError(componentVariableMutableValue.getComponentMutableKey(), componentVariableMutableValue.name);
        },
    });
    return { rawVariantValue };
};
