var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { until } from "@vueuse/core";
import { useSourceComponentVariable } from "../composables/useSourceComponentVariable";
import { ref, watch } from "vue";
import { getCampaignComponentVariableDefaultValue } from "../../campaign-component/variable/model/campaign-component-variable-value-defaults";
import { stringify } from "lossless-json";
import { useComponentVariable } from "../../component/composables/useComponentVariable";
import { isComponentVariableTranslatable } from "src/shared/tags/component-variables";
const areTagsDirty = (tags1, tags2) => {
    return stringify(tags1) !== stringify(tags2);
};
const areValuesDirty = (values1, values2) => {
    return stringify(values1) !== stringify(values2);
};
export class ComponentVariableMutable {
    constructor(componentMutableKey, mutableType, variable) {
        this.lastPulledDisplayName = ref(null);
        this.lastPulledDescription = ref(null);
        this.lastPulledTags = ref([]);
        this.lastPulledValues = ref({});
        this.lastPulledPriority = ref("0");
        this.lastPulledSource = ref("LOCAL");
        this.type = ref("STRING");
        this.displayName = ref(null);
        this.description = ref(null);
        this.tags = ref([]);
        this.values = ref({});
        this.priority = ref("0");
        this.source = ref("LOCAL");
        this.hasBecomeInherited = () => {
            return (this.source.value === "INHERITED" &&
                this.lastPulledSource.value === "LOCAL");
        };
        this.getVariants = () => {
            const values = this.getValues();
            return Object.keys(values);
        };
        this.getDefaultValue = (campaignDefaultVariant) => {
            if (this.hasVariant(campaignDefaultVariant)) {
                return this.getValue(campaignDefaultVariant);
            }
            else {
                return this.getValue("default");
            }
        };
        this.type = ref(variable.type);
        this.name = variable.name;
        this.mutableType = mutableType;
        this.context = {
            name: variable.name,
            componentId: variable.context.componentId,
            componentMutableKey,
        };
        watch(() => this.isDirty(), (value, originalValue) => {
            const hasBecomeDirty = value && !originalValue;
            if (hasBecomeDirty &&
                !this.hasBecomeInherited() &&
                this.mutableType === "EXISTING") {
                this.source.value = "LOCAL";
            }
        });
    }
    getType() {
        return this.type.value;
    }
    setType(type) {
        if (!this.isNew()) {
            return;
        }
        this.type.value = type;
        for (const variant of this.getVariants()) {
            this.setValue(variant, getCampaignComponentVariableDefaultValue(type));
        }
    }
    getComponentId() {
        return this.context.componentId;
    }
    getComponentMutableKey() {
        return this.context.componentMutableKey;
    }
    getValue(variant) {
        return this.values.value[variant];
    }
    getValues() {
        return this.values.value;
    }
    setValue(variant, value) {
        const clone = Object.assign({}, this.values.value);
        clone[variant] = value;
        this.values.value = clone;
    }
    getDisplayName() {
        return this.displayName.value;
    }
    setDisplayName(displayName) {
        this.displayName.value = displayName;
    }
    getTags() {
        return this.tags.value;
    }
    setTags(tags) {
        this.tags.value = tags;
    }
    getPriority() {
        return this.priority.value;
    }
    setPriority(priority) {
        this.priority.value = priority;
    }
    getDescription() {
        return this.description.value;
    }
    setDescription(description) {
        this.description.value = description;
    }
    getSource() {
        return this.source.value;
    }
    setSource(source) {
        this.source.value = source;
    }
    hasVariant(variant) {
        return Object.prototype.hasOwnProperty.call(this.values.value, variant);
    }
    addVariant(variant) {
        const clone = Object.assign({}, this.values.value);
        clone[variant] = getCampaignComponentVariableDefaultValue(this.getType());
        this.values.value = clone;
    }
    removeVariant(variant) {
        const clone = Object.assign({}, this.values.value);
        delete clone[variant];
        this.values.value = clone;
    }
    isNew() {
        return this.mutableType === "NEW";
    }
    pull() {
        return __awaiter(this, void 0, void 0, function* () {
            const { componentVariable } = useComponentVariable(this.context.componentId, this.name);
            const { sourceComponentVariable } = useSourceComponentVariable(this.context.componentId, this.name);
            yield until(sourceComponentVariable).not.toBeUndefined();
            yield until(componentVariable).not.toBeUndefined();
            if (!sourceComponentVariable.value || !componentVariable.value) {
                return;
            }
            this.lastPulledDisplayName.value = componentVariable.value.display_name;
            this.lastPulledDescription.value = componentVariable.value.description;
            this.lastPulledSource.value = componentVariable.value.source;
            this.lastPulledValues.value = Object.assign({}, sourceComponentVariable.value.values);
            this.lastPulledPriority.value = componentVariable.value.priority;
            this.lastPulledTags.value = [...sourceComponentVariable.value.tags];
            this.displayName.value = this.lastPulledDisplayName.value;
            this.description.value = this.lastPulledDescription.value;
            this.source.value = this.lastPulledSource.value;
            this.priority.value = this.lastPulledPriority.value;
            this.values.value = Object.assign({}, this.lastPulledValues.value);
            this.tags.value = [...this.lastPulledTags.value];
        });
    }
    isDirty() {
        const isDisplayNameDirty = this.displayName.value !== this.lastPulledDisplayName.value;
        const isDescriptionDirty = this.description.value !== this.lastPulledDescription.value;
        const isSourceDirty = this.source.value !== this.lastPulledSource.value;
        const _areTagsDirty = areTagsDirty(this.tags.value, this.lastPulledTags.value);
        const _areValuesDirty = areValuesDirty(this.values.value, this.lastPulledValues.value);
        return (isDescriptionDirty ||
            isDisplayNameDirty ||
            isSourceDirty ||
            _areTagsDirty ||
            _areValuesDirty);
    }
    isValueDirty(variant) {
        const value = this.values.value[variant];
        const lastPulledValue = this.lastPulledValues.value[variant];
        return value !== lastPulledValue;
    }
    isTranslatable() {
        return isComponentVariableTranslatable(this.getTags());
    }
    isMultiVariant() {
        return this.getVariants().length > 1;
    }
    supportsMultiVariantMode() {
        return this.isTranslatable() || this.isMultiVariant();
    }
    serializeToUpdateRequest() {
        const displayName = this.getDisplayName();
        const type = this.getType();
        const values = this.getValues();
        const source = this.getSource();
        const description = this.getDescription();
        const tags = this.getTags();
        const priority = this.getPriority();
        return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, (displayName && { display_name: displayName })), { type: type }), (values && { values: values })), (source && { source: source })), (description && { description: description })), (tags && { tags: tags })), { priority });
    }
    serializeToCreateRequest() {
        const displayName = this.getDisplayName();
        const type = this.getType();
        const values = this.getValues();
        const source = this.getSource();
        const description = this.getDescription();
        const tags = this.getTags();
        const priority = this.getPriority();
        return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ name: this.name }, (displayName && { display_name: displayName })), { type: type }), (values && { values: values })), (source && { source: source })), (description && { description: description })), (tags && { tags: tags })), { priority });
    }
}
