import { getComponentVariableImportance, setComponentVariableImportanceTag, } from "src/shared/tags/component-variables";
import { computed, toValue } from "vue";
export const useComponentVariableImportanceMutable = (componentVariableMutable) => {
    const importance = computed({
        get() {
            const componentVariableMutableValue = toValue(componentVariableMutable);
            if (!componentVariableMutableValue) {
                return null;
            }
            return getComponentVariableImportance(componentVariableMutableValue.getTags());
        },
        set(value) {
            const componentVariableMutableValue = toValue(componentVariableMutable);
            if (!componentVariableMutableValue) {
                return;
            }
            if (value === null) {
                return;
            }
            componentVariableMutableValue.setTags(setComponentVariableImportanceTag(componentVariableMutableValue.getTags(), value));
        },
    });
    return {
        importance,
    };
};
