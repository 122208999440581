import { computed, toValue } from "vue";
export const useComponentVariableSourceMutable = (componentVariableMutable) => {
    const source = computed({
        get() {
            const componentVariableMutableValue = toValue(componentVariableMutable);
            if (!componentVariableMutableValue) {
                return null;
            }
            return componentVariableMutableValue.getSource();
        },
        set(value) {
            const componentVariableMutableValue = toValue(componentVariableMutable);
            if (!componentVariableMutableValue || value === null) {
                return;
            }
            componentVariableMutableValue.setSource(value);
        },
    });
    return {
        source,
    };
};
