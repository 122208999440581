import { computed, toValue } from "vue";
export const useComponentVariablePriorityMutable = (componentVariableMutable) => {
    const priority = computed({
        get() {
            const componentVariableMutableValue = toValue(componentVariableMutable);
            if (!componentVariableMutableValue) {
                return "";
            }
            return componentVariableMutableValue.getPriority();
        },
        set(value) {
            const componentVariableMutableValue = toValue(componentVariableMutable);
            if (!componentVariableMutableValue) {
                return;
            }
            componentVariableMutableValue.setPriority(value);
        },
    });
    return {
        priority,
    };
};
