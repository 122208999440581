import { computed, toValue } from "vue";
export const useComponentVariableTagsMutable = (componentVariableMutable) => {
    const tags = computed({
        get() {
            const componentVariableMutableValue = toValue(componentVariableMutable);
            if (!componentVariableMutableValue) {
                return [];
            }
            return componentVariableMutableValue.getTags();
        },
        set(value) {
            const componentVariableMutableValue = toValue(componentVariableMutable);
            if (!componentVariableMutableValue) {
                return;
            }
            componentVariableMutableValue.setTags(value);
        },
    });
    return {
        tags,
    };
};
