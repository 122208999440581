import { getComponentVariablePrimaryCategoryIcon, OTHER_CATEGORY, } from "src/shared/tags/component-variables";
import Strings from "src/shared/Strings";
export class PrimaryCategory {
    constructor(parameters) {
        this.name = parameters.name;
        this.priority = parameters.priority;
        this.secondaryCategories = parameters.secondaryCategories;
        this.isEveryVariableAdvanced = parameters.isEveryVariableAdvanced;
    }
    isOther() {
        return this.name === OTHER_CATEGORY;
    }
    getDisplayName() {
        return Strings.capitalize(this.name);
    }
    getIcon() {
        return getComponentVariablePrimaryCategoryIcon(this.name);
    }
}
export class SecondaryCategory {
    constructor(parameters) {
        this.name = parameters.name;
        this.priority = parameters.priority;
        this.isEveryVariableAdvanced = parameters.isEveryVariableAdvanced;
        this.variables = organizeComponentVariables(parameters.variables);
    }
    getDisplayName() {
        return Strings.capitalize(this.name);
    }
    isOther() {
        return this.name === OTHER_CATEGORY;
    }
}
const compareDeweyDecimal = (a, b) => {
    const parseDewey = (s) => s.split(".").map(Number);
    const partsA = parseDewey(a.priority);
    const partsB = parseDewey(b.priority);
    for (let i = 0; i < Math.min(partsA.length, partsB.length); i++) {
        if (partsA[i] < partsB[i])
            return -1;
        if (partsA[i] > partsB[i])
            return 1;
    }
    return partsA.length - partsB.length;
};
const advancedImportanceLevels = [
    "EXPERT",
    "ADVANCED",
];
export const isComponentVariableAdvanced = (variable) => {
    return advancedImportanceLevels.includes(variable.getImportance());
};
class SecondaryCategoryBuilder {
    constructor(name) {
        this.hasAdvanced = false;
        this.hasBasic = false;
        this.isEveryVariableAdvanced = true;
        this.variables = {};
        this.priority = "10000000000";
        this.name = name;
    }
    updatePriority(priority) {
        if (this.name === OTHER_CATEGORY) {
            return;
        }
        if (compareDeweyDecimal({ priority: priority }, { priority: this.priority }) === -1) {
            this.priority = priority;
        }
    }
    updateHasAdvanced(importance) {
        if (this.hasAdvanced) {
            return;
        }
        this.hasAdvanced = advancedImportanceLevels.includes(importance);
    }
    updateHasBasic(importance) {
        if (this.hasBasic) {
            return;
        }
        this.hasBasic = !advancedImportanceLevels.includes(importance);
    }
    withVariable(variable) {
        this.variables[variable.name] = variable;
        this.updatePriority(variable.priority);
        this.updateHasAdvanced(variable.getImportance());
        this.updateHasBasic(variable.getImportance());
        if (!isComponentVariableAdvanced(variable)) {
            this.isEveryVariableAdvanced = false;
        }
        return this;
    }
    build() {
        const variables = Object.values(this.variables);
        return new SecondaryCategory({
            name: this.name,
            priority: this.priority,
            isEveryVariableAdvanced: this.isEveryVariableAdvanced,
            variables,
        });
    }
}
class PrimaryCategoryBuilder {
    constructor(name) {
        this.variablePriorities = [];
        this.secondaryCategoryBuilders = {};
        this.priority = "10000000000";
        this.name = name;
    }
    provideSecondaryCategoryBuilder(name) {
        if (!this.secondaryCategoryBuilders[name]) {
            this.secondaryCategoryBuilders[name] = new SecondaryCategoryBuilder(name);
        }
        return this.secondaryCategoryBuilders[name];
    }
    updatePriority(priority) {
        if (this.name === OTHER_CATEGORY) {
            return;
        }
        if (compareDeweyDecimal({ priority: priority }, { priority: this.priority }) === -1) {
            this.priority = priority;
        }
    }
    withVariable(variable) {
        this.provideSecondaryCategoryBuilder(variable.getSecondaryCategory()).withVariable(variable);
        this.updatePriority(variable.priority);
        this.variablePriorities.push(variable.priority);
    }
    build() {
        const secondaryCategories = Object.values(this.secondaryCategoryBuilders)
            .sort((categoryA, categoryB) => {
            return compareDeweyDecimal({
                priority: categoryA.priority,
            }, {
                priority: categoryB.priority,
            });
        })
            .map((builder) => {
            return builder.build();
        });
        const isEveryVariableAdvanced = secondaryCategories.every((category) => category.isEveryVariableAdvanced);
        return new PrimaryCategory({
            name: this.name,
            icon: getComponentVariablePrimaryCategoryIcon(this.name),
            priority: this.priority,
            isEveryVariableAdvanced,
            secondaryCategories,
        });
    }
}
class CategorizedComponentVariableListBuilder {
    constructor() {
        this.primaryCategoryBuilders = {};
    }
    providePrimaryCategoryBuilder(name) {
        if (!this.primaryCategoryBuilders[name]) {
            this.primaryCategoryBuilders[name] = new PrimaryCategoryBuilder(name);
        }
        return this.primaryCategoryBuilders[name];
    }
    withVariable(variable) {
        this.providePrimaryCategoryBuilder(variable.getPrimaryCategory()).withVariable(variable);
    }
    build() {
        const categories = Object.values(this.primaryCategoryBuilders)
            .sort((categoryA, categoryB) => {
            return compareDeweyDecimal({
                priority: categoryA.priority,
            }, {
                priority: categoryB.priority,
            });
        })
            .map((builder) => {
            return builder.build();
        });
        return categories;
    }
}
export const categorizeComponentVariables = (variables) => {
    const builder = new CategorizedComponentVariableListBuilder();
    variables.forEach((variable) => {
        builder.withVariable(variable);
    });
    return builder.build();
};
export const organizeComponentVariables = (variables) => {
    const basic = [];
    const advanced = [];
    const sortedVariables = variables.sort((a, b) => {
        return compareDeweyDecimal({ priority: a.priority }, { priority: b.priority });
    });
    sortedVariables.forEach((variable) => {
        if (isComponentVariableAdvanced(variable)) {
            advanced.push(variable);
        }
        else {
            basic.push(variable);
        }
    });
    return { basic, advanced };
};
