import { computed, toValue } from "vue";
import { useComponentMutable } from "./useComponentMutable";
export const useComponentVariableMutable = (componentMutableKey, variableName) => {
    const { componentMutable } = useComponentMutable(componentMutableKey);
    const componentVariableMutable = computed(() => {
        const componentMutableValue = toValue(componentMutable);
        const variableNameValue = toValue(variableName);
        if (!componentMutableValue || !variableNameValue) {
            return;
        }
        return componentMutableValue.getVariable(variableNameValue);
    });
    return { componentVariableMutable };
};
