import Strings from "../Strings";
import { assertNever } from "../tools/assertNever";
import { includes } from "lodash";
export const COMPONENT_VARIABLE_TRANSLATABLE_TAG = "translatable";
const hasTag = (tags, tag) => {
    return includes(tags, tag);
};
const addTag = (tags, tag) => {
    if (!hasTag(tags, tag)) {
        tags.push(tag);
    }
};
const removeTag = (tags, tag) => {
    if (hasTag(tags, tag)) {
        tags.splice(tags.indexOf(tag), 1);
    }
};
const toggleTag = (tags, tag) => {
    if (hasTag(tags, tag)) {
        removeTag(tags, tag);
    }
    else {
        addTag(tags, tag);
    }
};
// Category Tag Format: category:primary:secondary
const CATEGORY_TAG_PREFIX_PATTERN = /^category:/;
const DEFAULT_PRIMARY_CATEGORY_ICON = "far fa-circle";
export const OTHER_CATEGORY = "other";
const categoryAliases = {
    styling: "styles",
    reward_settings: "rewards",
    image: "images",
};
const applyCategoryAliases = (category) => {
    return (categoryAliases[category] ||
        categoryAliases[Strings.capitalize(category)] ||
        category);
};
const primaryCategoriesByVariableType = {
    STRING: "content",
    BOOLEAN: "behavior",
    INTEGER: "behavior",
    STRING_LIST: "content",
    INTEGER_LIST: "content",
    JSON: "content",
    COLOR: "styles",
    IMAGE: "styles",
    URL: "content",
    CLIENT_KEY_FLOW: "behavior",
    CLIENT_KEY: "behavior",
    EXTOLE_CLIENT_KEY: "behavior",
    REWARD_SUPPLIER_ID: "behavior",
    AUDIENCE_ID: "behavior",
    ENUM: "behavior",
};
const primaryCategoryIcons = {
    all_categories: "far fa-list",
    styles: "far fa-palette",
    behavior: "far fa-cogs",
    content: "far fa-file-alt",
    company_details: "far fa-copyright",
    legal_and_security: "far fa-shield-alt",
    sharing: "far fa-share",
    rewards: "far fa-trophy",
    emails: "far fa-envelope",
    marketing: "far fa-badge-percent",
    legacy: "far fa-minus-circle",
};
const secondaryCategoriesByVariableType = {
    STRING: {
        styles: "css",
    },
    BOOLEAN: {},
    INTEGER: {},
    STRING_LIST: {},
    INTEGER_LIST: {},
    JSON: {},
    COLOR: {
        styles: "colors",
    },
    IMAGE: {},
    URL: {},
    CLIENT_KEY_FLOW: {},
    CLIENT_KEY: {},
    EXTOLE_CLIENT_KEY: {},
    REWARD_SUPPLIER_ID: {},
    AUDIENCE_ID: {},
    ENUM: {},
};
const getCategoryTag = (tags) => {
    return tags.find((tag) => {
        return CATEGORY_TAG_PREFIX_PATTERN.test(tag);
    });
};
const normalizeCategory = (category) => {
    return category ? Strings.encodeSnakeCase(category) : category;
};
const getCategoryData = (type, tags) => {
    const tag = getCategoryTag(tags);
    if (!tag) {
        return {
            primary: undefined,
            secondary: undefined,
        };
    }
    const withoutPrefix = tag.replace(CATEGORY_TAG_PREFIX_PATTERN, "");
    const [primary, secondary] = withoutPrefix.split(":");
    return {
        primary: normalizeCategory(primary),
        secondary: normalizeCategory(secondary),
    };
};
const getComponentVariablePrimaryCategory = (type, tags) => {
    const categoryData = getCategoryData(type, tags);
    const { primary } = categoryData;
    if (primary) {
        return primary;
    }
    return primaryCategoriesByVariableType[type];
};
export const getComponentVariableCategories = (type, tags) => {
    const { primary, secondary } = getCategoryData(type, tags);
    const primaryCategory = applyCategoryAliases(primary || getComponentVariablePrimaryCategory(type, tags));
    const secondaryCategory = applyCategoryAliases(secondary ||
        secondaryCategoriesByVariableType[type][primaryCategory] ||
        OTHER_CATEGORY);
    return {
        primary: primaryCategory,
        secondary: secondaryCategory,
    };
};
export const getComponentVariablePrimaryCategoryIcon = (primaryCategory) => {
    return (primaryCategoryIcons[primaryCategory] || DEFAULT_PRIMARY_CATEGORY_ICON);
};
export const isComponentVariableTranslatable = (tags) => {
    return tags.includes(COMPONENT_VARIABLE_TRANSLATABLE_TAG);
};
export const isComponentVariableUIDisplayOnly = (tags) => {
    return tags.includes("internal:ui-display");
};
export const getComponentVariableImportance = (tags) => {
    if (tags.includes("importance:essential")) {
        return "ESSENTIAL";
    }
    if (tags.includes("importance:expert") ||
        tags.includes("internal:hidden")) {
        return "EXPERT";
    }
    if (tags.includes("importance:advanced")) {
        return "ADVANCED";
    }
    return "BASIC";
};
export const toggleComponentVariableTranslatableTag = (tags) => {
    toggleTag(tags, COMPONENT_VARIABLE_TRANSLATABLE_TAG);
};
export const setComponentVariableImportanceTag = (tags, importance) => {
    tags = tags.filter((tag) => !tag.startsWith("importance:"));
    switch (importance) {
        case "ESSENTIAL":
            tags.push("importance:essential");
            break;
        case "EXPERT":
            tags.push("importance:expert");
            break;
        case "ADVANCED":
            tags.push("importance:advanced");
            break;
        case "BASIC":
            tags.push("importance:basic");
            break;
        default:
            assertNever(importance);
    }
    return tags;
};
