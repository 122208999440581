import { useBuiltCampaign } from "src/shared/store/campaign-built/composables";
import { getCampaignFrontendAssets } from "src/shared/store/campaign-built/model/frontend-asset/getCampaignFrontendAssets";
import { useBuiltComponent } from "src/shared/store/component-built/composables";
import { useParentComponentIds } from "src/shared/store/component-graph/composables";
import { computed, toValue } from "vue";
const getCampaignEditorOverviewPath = (campaignId) => {
    return `/campaign/edit/#/${campaignId}/assets/overview`;
};
const getCampaignEditorCreativePath = (campaignId, frontendAssetHandle) => {
    return `/campaign/edit/#/${campaignId}/assets/${frontendAssetHandle}`;
};
const getAccountSettingsPath = (componentId) => {
    return `/account-settings/#/${componentId}`;
};
export const useCampaignEditorPath = (componentId) => {
    const { builtComponent } = useBuiltComponent(componentId);
    const { builtCampaign } = useBuiltCampaign(() => { var _a; return (_a = builtComponent.value) === null || _a === void 0 ? void 0 : _a.campaign_id; });
    const parentComponentIds = useParentComponentIds(() => { var _a; return (_a = builtComponent.value) === null || _a === void 0 ? void 0 : _a.id; });
    return computed(() => {
        if (!builtComponent.value || !builtCampaign.value) {
            return null;
        }
        const hasParents = parentComponentIds.value.length > 0;
        if (builtComponent.value.name === "root") {
            if (hasParents) {
                return getCampaignEditorOverviewPath(builtComponent.value.campaign_id);
            }
        }
        const campaignFrontendAssets = getCampaignFrontendAssets(builtCampaign.value);
        const frontendAsset = campaignFrontendAssets.find((asset) => {
            return asset.getComponentId() === toValue(componentId);
        });
        if (frontendAsset) {
            return getCampaignEditorCreativePath(builtComponent.value.campaign_id, frontendAsset.getAssetHandle());
        }
        return getAccountSettingsPath(builtComponent.value.id);
    });
};
