import { computed, toValue } from "vue";
export const useComponentVariableDescriptionMutable = (componentVariableMutable) => {
    const description = computed({
        get() {
            const componentVariableMutableValue = toValue(componentVariableMutable);
            if (!componentVariableMutableValue) {
                return "";
            }
            return componentVariableMutableValue.getDescription();
        },
        set(value) {
            const componentVariableMutableValue = toValue(componentVariableMutable);
            if (!componentVariableMutableValue) {
                return;
            }
            componentVariableMutableValue.setDescription(value);
        },
    });
    return {
        description,
    };
};
