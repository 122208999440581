var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { defineStore } from "pinia";
import ComponentEndpoints from "src/shared/api/campaign/component/ComponentEndpoints";
import { toPromise } from "src/shared/api/promise";
import _ from "underscore";
import { reactive } from "vue";
import Component from "./model/Component";
import { campaignChangeEvent } from "../event-bus/campaign";
import { campaignComponentChangeEvent, campaignComponentDeletedEvent, campaignComponentListChangeEvent, campaignComponentUpdatedEvent, } from "../event-bus/campaign-component";
import CampaignComponentEndpoints from "src/shared/api/campaign/component/CampaignComponentEndpoints";
import { useBuiltComponent } from "../component-built/composables";
import { until } from "@vueuse/core";
import BatchVariableEndpoints from "src/shared/api/campaign/component/variable/batch/BatchVariableEndpoints";
import CampaignComponentVariableEndpoints from "src/shared/api/campaign/component/variable/CampaignComponentVariableEndpoints";
import { doCampaignOperation } from "../control-flow/campaign-operation-queue";
const convertToBatchUpdateRequest = (variables) => {
    const request = {
        variables: {},
    };
    variables.forEach((variable) => {
        request.variables[variable.name] = variable.serializeToUpdateRequest();
    });
    console.log(request);
    return request;
};
const loadComponentUpdateHandle = (componentId) => __awaiter(void 0, void 0, void 0, function* () {
    const { builtComponent } = useBuiltComponent(componentId);
    yield until(builtComponent).not.toBeUndefined();
    if (builtComponent.value === undefined) {
        throw new Error("Component not found");
    }
    return {
        componentId: builtComponent.value.id,
        campaignId: builtComponent.value.campaign_id,
    };
});
const convertToVariableCreateRequests = (variables) => {
    return _.map(variables, (variable) => {
        return {
            name: variable.name,
            type: variable.type,
            values: variable.values,
            source: variable.source,
            description: variable.description,
            tags: variable.tags,
            priority: variable.priority,
        };
    });
};
const convertToVariableCreateRequestsV2 = (variables) => {
    return _.map(variables, (variable) => {
        return variable.serializeToCreateRequest();
    });
};
const convertToComponentCreateRequest = (parameters) => {
    return Object.assign(Object.assign(Object.assign(Object.assign({ name: parameters.name, component_version: parameters.component_version }, (parameters.description !== null &&
        !_.isEmpty(parameters.description) && {
        description: parameters.description,
    })), (parameters.tags !== null &&
        !_.isEmpty(parameters.tags) && {
        tags: parameters.tags,
    })), (parameters.variables !== null &&
        !_.isEmpty(parameters.variables) && {
        variables: convertToVariableCreateRequests(parameters.variables),
    })), (parameters.component_ids &&
        !_.isEmpty(parameters.component_ids) && {
        component_ids: parameters.component_ids,
    }));
};
const convertToComponentUpdateRequest = (mutable) => {
    return {
        name: mutable.getName(),
    };
};
const convertToComponentDuplicateRequest = (parameters) => {
    return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, (parameters.targetCampaignId && {
        target_campaign_id: parameters.targetCampaignId,
    })), (parameters.componentName && {
        component_name: parameters.componentName,
    })), (parameters.targetComponentId && {
        component_ids: [parameters.targetComponentId],
    })), (parameters.tags &&
        !_.isEmpty(parameters.tags) && {
        tags: parameters.tags,
    })), (parameters.variables &&
        !_.isEmpty(parameters.variables) && {
        variables: convertToVariableCreateRequests(parameters.variables),
    }));
};
const convertToComponentDuplicateRequestV2 = (parameters) => {
    return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, (parameters.targetCampaignId && {
        target_campaign_id: parameters.targetCampaignId,
    })), (parameters.componentName && {
        component_name: parameters.componentName,
    })), (parameters.targetComponentId && {
        component_ids: [parameters.targetComponentId],
    })), (parameters.tags &&
        !_.isEmpty(parameters.tags) && {
        tags: parameters.tags,
    })), (parameters.variables &&
        !_.isEmpty(parameters.variables) && {
        variables: convertToVariableCreateRequestsV2(parameters.variables),
    }));
};
export const useComponentStoreV2 = defineStore("componentStoreV2", () => {
    const state = reactive({
        components: {},
    });
    const setComponents = (componentResponses) => {
        return _.map(componentResponses, (response) => {
            const component = new Component(response);
            state.components[component.id] = component;
            campaignComponentUpdatedEvent.emit({
                campaignId: component.campaign_id,
                componentId: component.id,
            });
            return component;
        });
    };
    const fetchComponents = () => __awaiter(void 0, void 0, void 0, function* () {
        setComponents(yield toPromise(ComponentEndpoints.list()));
    });
    const fetchComponent = (componentId) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield toPromise(ComponentEndpoints.get(componentId));
        const [component] = setComponents([response]);
        return component;
    });
    const fetchComponentDuplicationCandidates = (targetComponentId) => __awaiter(void 0, void 0, void 0, function* () {
        return setComponents(yield toPromise(ComponentEndpoints.list({
            target_component_id: targetComponentId,
        })));
    });
    const getComponents = () => {
        return _.values(state.components);
    };
    const getComponent = (id) => {
        return state.components[id];
    };
    const duplicateComponent = (parameters) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield toPromise(ComponentEndpoints.duplicate(parameters.sourceComponentId, convertToComponentDuplicateRequest(parameters)));
        campaignChangeEvent.emit({
            campaignId: response.campaign_id,
        });
        campaignComponentListChangeEvent.emit({
            campaignId: response.campaign_id,
        });
        return {
            campaignId: response.campaign_id,
        };
    });
    const duplicateComponentV2 = (parameters) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield toPromise(ComponentEndpoints.duplicate(parameters.sourceComponentId, convertToComponentDuplicateRequestV2(parameters)));
        campaignChangeEvent.emit({
            campaignId: response.campaign_id,
        });
        campaignComponentListChangeEvent.emit({
            campaignId: response.campaign_id,
        });
        return {
            campaignId: response.campaign_id,
        };
    });
    const createComponent = (campaignId, parameters) => __awaiter(void 0, void 0, void 0, function* () {
        const request = convertToComponentCreateRequest(parameters);
        try {
            yield toPromise(CampaignComponentEndpoints.create(campaignId, request));
            campaignChangeEvent.emit({
                campaignId: campaignId,
            });
            campaignComponentListChangeEvent.emit({
                campaignId: campaignId,
            });
        }
        catch (error) {
            console.log(error);
        }
    });
    const updateComponent = (campaignId, componentId, mutable) => __awaiter(void 0, void 0, void 0, function* () {
        const request = convertToComponentUpdateRequest(mutable);
        yield toPromise(CampaignComponentEndpoints.update(campaignId, componentId, request));
        campaignChangeEvent.emit({
            campaignId: campaignId,
        });
        campaignComponentChangeEvent.emit({
            campaignId: campaignId,
            componentId: componentId,
        });
        campaignComponentListChangeEvent.emit({
            campaignId: campaignId,
        });
    });
    const deleteComponent = (campaignId, componentId) => __awaiter(void 0, void 0, void 0, function* () {
        const deletedComponent = getComponent(componentId);
        yield toPromise(CampaignComponentEndpoints.deleteCampaignComponent(campaignId, componentId));
        if (!(deletedComponent === null || deletedComponent === void 0 ? void 0 : deletedComponent.isRoot())) {
            campaignChangeEvent.emit({
                campaignId: campaignId,
            });
        }
        campaignComponentDeletedEvent.emit({
            campaignId: campaignId,
            componentId: componentId,
        });
    });
    const createComponentVariable = (componentId, variableName, variable) => __awaiter(void 0, void 0, void 0, function* () {
        const { campaignId } = yield loadComponentUpdateHandle(componentId);
        const request = variable.serializeToCreateRequest();
        request.name = variableName;
        yield doCampaignOperation(campaignId, () => __awaiter(void 0, void 0, void 0, function* () {
            yield CampaignComponentVariableEndpoints.create(campaignId, componentId, request);
            campaignComponentChangeEvent.emit({
                campaignId: campaignId,
                componentId: componentId,
            });
        }));
    });
    const batchUpdateComponentVariables = (componentId, variables) => __awaiter(void 0, void 0, void 0, function* () {
        const { campaignId } = yield loadComponentUpdateHandle(componentId);
        const request = convertToBatchUpdateRequest(variables);
        yield doCampaignOperation(campaignId, () => __awaiter(void 0, void 0, void 0, function* () {
            yield toPromise(BatchVariableEndpoints.update(campaignId, componentId, request));
            campaignComponentChangeEvent.emit({
                campaignId: campaignId,
                componentId: componentId,
            });
        }));
    });
    const deleteComponentVariable = (componentId, variableName) => __awaiter(void 0, void 0, void 0, function* () {
        const { campaignId } = yield loadComponentUpdateHandle(componentId);
        yield doCampaignOperation(campaignId, () => __awaiter(void 0, void 0, void 0, function* () {
            yield CampaignComponentVariableEndpoints.destroy(campaignId, componentId, variableName);
            campaignComponentChangeEvent.emit({
                campaignId: campaignId,
                componentId: componentId,
            });
        }));
    });
    campaignComponentChangeEvent.on((event) => {
        fetchComponent(event.componentId);
    });
    campaignComponentDeletedEvent.on((payload) => {
        delete state.components[payload.componentId];
    });
    return {
        state,
        fetchComponent,
        fetchComponents,
        fetchComponentDuplicationCandidates,
        getComponents,
        getComponent,
        duplicateComponent,
        duplicateComponentV2,
        updateComponent,
        createComponent,
        deleteComponent,
        batchUpdateComponentVariables,
        createComponentVariable,
        deleteComponentVariable,
    };
});
