var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import notificationService from "src/shared/notification/notification-service";
import { useCampaignComponentVariableStore } from "src/shared/store/campaign-component/variable";
import { useCampaignComponentVariable } from "src/shared/store/campaign-component/variable/composables";
import CampaignComponentVariableUpdateMutable from "src/shared/store/campaign-component/variable/model/CampaignComponentVariableUpdateMutable";
import { getErrorMessage } from "src/shared/store/common/Exception";
import { useComponentDisplayName } from "src/shared/store/component-built/composables";
import { computed } from "vue";
export const usePushValuesToParent = (builtVariable, parentComponentVariable, isPushingToParent) => {
    const push = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!builtVariable.value) {
            return;
        }
        if (!parentComponentVariable.value) {
            return;
        }
        const campaignComponentVariableStore = useCampaignComponentVariableStore();
        const { campaignComponentVariable } = useCampaignComponentVariable(builtVariable.value.context.campaignId, builtVariable.value.context.componentId, builtVariable.value.name);
        if (!campaignComponentVariable.value) {
            return;
        }
        const parentComponentDisplayName = computed(() => {
            var _a, _b;
            if (!((_a = parentComponentVariable.value) === null || _a === void 0 ? void 0 : _a.context.componentId)) {
                return undefined;
            }
            return useComponentDisplayName((_b = parentComponentVariable.value) === null || _b === void 0 ? void 0 : _b.context.componentId).displayName.value;
        });
        if (!parentComponentDisplayName.value) {
            return;
        }
        try {
            isPushingToParent.value = true;
            yield campaignComponentVariableStore.updateVariableV2(parentComponentVariable.value.context.campaignId, parentComponentVariable.value.context.componentId, parentComponentVariable.value.name, new CampaignComponentVariableUpdateMutable({
                type: parentComponentVariable.value.type,
                name: campaignComponentVariable.value.name,
                values: campaignComponentVariable.value.values,
            }));
            yield campaignComponentVariableStore.updateVariableV2(builtVariable.value.context.campaignId, builtVariable.value.context.componentId, builtVariable.value.name, new CampaignComponentVariableUpdateMutable({
                type: builtVariable.value.type,
                name: campaignComponentVariable.value.name,
                source: "INHERITED",
            }));
            notificationService.success(`"${parentComponentDisplayName.value}" has been updated`);
        }
        catch (e) {
            notificationService.error(getErrorMessage(e));
        }
        finally {
            isPushingToParent.value = false;
        }
    });
    return {
        push,
    };
};
