import { useLocalStorage } from "@vueuse/core";
const masterSwitch = useLocalStorage("component-variable-advanced-master-switch", false);
export const useComponentVariableAdvancedMasterSwitch = () => {
    const showAdvanced = () => {
        return masterSwitch.value;
    };
    const toggle = () => {
        masterSwitch.value = !masterSwitch.value;
    };
    return {
        showAdvanced,
        toggle,
    };
};
