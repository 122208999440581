var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useAiConversationStore } from ".";
export const translateMessage = (parameters) => __awaiter(void 0, void 0, void 0, function* () {
    const aiConversationStore = useAiConversationStore();
    const conversationId = yield aiConversationStore.startConversationWithMessage("translate", parameters.message, {
        language: parameters.language,
        target_language: parameters.targetLanguage,
    });
    const [userMessage, aiMessage] = aiConversationStore.getConversationMessages(conversationId);
    const translatedMessage = aiMessage.content;
    return {
        translatedMessage,
    };
});
