var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ref, toValue, watch } from "vue";
import { createComponentMutableForEditing } from "../service/createComponentMutableForEditing";
export const useComponentMutableKeyForEditing = (componentId, externalHandle) => {
    const componentMutableKey = ref(null);
    const setComponentMutableKey = (componentId, externalHandle) => __awaiter(void 0, void 0, void 0, function* () {
        componentMutableKey.value = null;
        if (!componentId) {
            return;
        }
        const { componentMutableKey: newComponentMutableKey } = yield createComponentMutableForEditing({
            componentId,
            externalHandle,
        });
        componentMutableKey.value = newComponentMutableKey;
    });
    watch(() => `${toValue(componentId)}-${toValue(externalHandle)}`, () => {
        setComponentMutableKey(toValue(componentId), toValue(externalHandle));
    }, {
        immediate: true,
    });
    return {
        componentMutableKey,
    };
};
